
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400&display=swap');
.Ar{
  font-family: 'Cairo', sans-serif;
}

  body{
    /* background-color: #0b0132 */
    background-color: #0d1117;
    
}

.rtl{direction: rtl;}

.banner-video-overlay {
    
    z-index: -1;
    background-image: url('/public/overlay2.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    border-radius: 0;
}

.spacer{
    aspect-ratio: 960/300;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.layer{
    background-image: url('./layer.svg');
}
.layer2{
    background-image: url('./layer2.svg');
}


.flip {
    transform: rotate(180deg);
  }
  .blob-motion {
    position: absolute;
    transform: translateY(-20%);
    z-index: 0;
  }
  
  .blob-content {
    z-index: 1;
  }
  .blobs {
    width: 100%;
    background: url('./blobs.svg') no-repeat bottom / cover;
  }
  
  
  .blob-motion {
    position: absolute;
    transform: translateY(-20%);
    z-index: -1;
  }
  
  